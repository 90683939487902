import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useRouter } from 'next/router';
import * as React from 'react';

import ROUTE from '../constants/route';

const Root = () => {
  /**
   * dynamic-pathなどの場合、`pages/index.tsx`がSSRのデフォルトとして読み込まれるため、
   * SSRではそのまま、CSRではリダイレクトする.
   *
   *  SSR: Rootがレンダリング -> Contentがレンダリング
   *  CSR: Contentがレンダリング
   *
   * このコンポーネントはローディング画面とすることで、一覧のUIの一貫性を保つ.
   */

  const router = useRouter();
  React.useEffect(() => {
    if (location.pathname === '/') {
      router.push(ROUTE.TOP.url);
    }
  }, [location.pathname]);

  return (
    <Box display="flex" width={'100vw'} height={'80vh'}>
      <Box m="auto">
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Root;
